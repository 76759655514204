<template>
  <div v-if="project">

    <div class="row">

      <div class="col-md-8">
        <div class="card chart-card general-info-card">
          <div class="card-body">
            <div class="row">
              <div class="col-xs-6 col-sm-6 col-md-6">
                <h5><b>PIM ANALYTICS DASHBOARD</b></h5>
                <br>
                <p><b>Project:</b> {{ project.title }}</p>
                <p><b>Date:</b> {{ project.date }}</p>
                <p><b>Company:</b> {{ project.company.name }}</p>
                <p><b>Total Number Of Packages:</b> {{ project.number_of_packages }}</p>
              </div>
              <div class="col-xs-6 col-sm-6 col-md-6">
                <div v-if="project.logo" class="logo-container">
                  <img :src="getSecureImgByUrl(project.logo)" class="logo">
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-xs-12 col-sm-12 col-md-12">
                <p><b>Description:</b> {{ project.description }}</p>
              </div>
            </div>
            <div class="row mt-3">
              <div class="col-xs-12 col-sm-12 col-md-12">
                <div>
                  <l-button
                    @click="printWindow()"
                    class="mr-lg-1">
             <span style="margin-right: 3px" class="btn-label">
                    <i class="fa fa-print"></i>
             </span>
                    Printer Friendly
                  </l-button>

                  <l-button
                    @click="viewProof()"
                    class="mr-lg-1"
                    type="warning">
             <span style="margin-right: 3px" class="btn-label">
             </span>
                    View Proof
                  </l-button>

                  <l-button
                    @click="viewDocuments()"
                    class="mr-lg-1"
                    type="info">
             <span style="margin-right: 3px" class="btn-label">
             </span>
                    View Documents
                  </l-button>

                  <l-button
                    @click="downloadList()"
                    class="mr-lg-1"
                    type="success">
             <span style="margin-right: 3px" class="btn-label">
               <i class="fa fa-download"></i>
             </span>
                    Download List
                  </l-button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="col-md-4">
        <div class="card chart-card" style="height: 90%">
          <div class="card-header" style="text-align: center">
            <h4>Delivery Percentage</h4>
          </div>
          <div class="card-body" style="text-align: center">
            <el-progress :width="200" type="circle" :stroke-width="20" :color="'#87CB16'"
                         :percentage="deliveryPercentage"></el-progress>
          </div>
          <div class="card-footer">
          </div>
        </div>
      </div>

    </div>

    <div class="row">
      <div class="col-md-8">
        <shipped-data-bar-chart :data="getNumberOfShippedBarChartData"
                                :total-number-of-packages="getTotalNumbersOfItems">
        </shipped-data-bar-chart>
      </div>
      <div class="col-md-4">
        <shipped-data-pie-chart :data="getNumberOfShippedBarChartData"
                                :number-of-shipped-packages="getTotalNumbersOfShippedItems"
                                :total-number-of-packages="getTotalNumbersOfItems">
        </shipped-data-pie-chart>
      </div>
    </div>

    <div class="row">
      <div class="col-md-8">
        <status-data-bar-chart :data="getStatusBarChartData"
                               :number-of-shipped-packages="getTotalNumbersOfShippedItems"
                               :project-id="project.uuid">
        </status-data-bar-chart>
      </div>
      <div class="col-md-4">
        <status-data-pie-chart :data="getStatusPieChartData"
                               :total-number-of-packages="getTotalNumbersOfItems"
                               :number-of-shipped-packages="getTotalNumbersOfShippedItems"
                               :project-id="project.uuid">
        </status-data-pie-chart>
      </div>
    </div>

    <div class="row">
      <div class="col-md-12">
        <countries-data-geo-chart :data="countriesChartData"
                                  :number-of-shipped-packages="getTotalNumbersOfShippedItems">
        </countries-data-geo-chart>
      </div>
    </div>

    <div class="row">
      <div class="col-md-12">
        <top-countries :data="topCountriesData" :allow-sorting="true"
                       :state-table-columns="topCountriesColumns"></top-countries>
      </div>
    </div>

    <div class="row">
      <div class="col-md-12">
        <usa-data-geo-chart :data="usaChartData"
                            :number-of-shipped-packages="getTotalNumbersOfShippedItems">
        </usa-data-geo-chart>
      </div>
    </div>

    <div class="row">
      <div class="col-md-12">
        <top-usa-states :data="topUsaStatesData" :allow-sorting="true"
                        :state-table-columns="topUsaStatesColumns"></top-usa-states>
      </div>
    </div>

    <div class="row">
      <div class="col-md-12">
        <PackagesByStateTable :data="stateTableData" :allow-sorting="true"
                              :state-table-columns="stateTableColumns"></PackagesByStateTable>
      </div>
    </div>

    <vue-html2pdf
      :show-layout="false"
      :float-layout="true"
      :enable-download="true"
      :preview-modal="false"
      :paginate-elements-by-height="1400"
      :filename="getPrintedFileName"
      :pdf-quality="2"
      :manual-pagination="false"
      pdf-format="a4"
      pdf-orientation="landscape"
      pdf-content-width="1100px"
      ref="html2Pdf">
      <section slot="pdf-content">
        <div class="row pdf-info">
          <div class="col-md-12">
            <div class="card chart-card">
              <div class="card-header">
              </div>
              <div class="card-body">
                <div class="general-info-img-flex">
                  <img class="general-info-img" src="static/pim-tv-logo.png" alt="Pim Live Logo">
                  <img v-if="project.company.logo" class="general-info-img"
                       :src="getSecureImgByUrl(project.company.logo)"
                       alt="Company Logo">
                </div>

                <div class="row">
                  <div class="col-xs-8 col-sm-8 col-md-8">
                    <p><b>Title:</b> {{ project.title }}</p>
                    <p><b>Date:</b> {{ project.date }}</p>
                    <p><b>Company:</b> {{ project.company.name }}</p>
                    <p><b>Number Of Packages:</b> {{ project.number_of_packages }}</p>
                    <p><b>Print Date:</b> {{ new Date() | moment("MMMM Do YYYY") }}</p>
                    <p><b>Delivery Percentage:</b> {{ deliveryPercentage }}%</p>
                    <p><b>Description:</b> {{ project.description }}</p>
                  </div>
                  <div class="col-xs-4 col-sm-4 col-md-4">
                    <div class="logo-container left">
                      <img v-if="project.logo" class="logo" :src="getSecureImgByUrl(project.logo)"
                           alt="Project Logo">
                    </div>
                  </div>
                </div>
              </div>


              <div class="card-footer">
              </div>
            </div>
          </div>

        </div>
        <div class="html2pdf__page-break"/>

        <div class="row" style="margin-top: 40px">
          <div class="col-md-8">
            <shipped-data-bar-chart :data="getNumberOfShippedBarChartData"
                                    :total-number-of-packages="getTotalNumbersOfItems">
            </shipped-data-bar-chart>
          </div>
          <div class="col-md-4">
            <shipped-data-pie-chart :data="getNumberOfShippedBarChartData"
                                    :number-of-shipped-packages="getTotalNumbersOfShippedItems"
                                    :total-number-of-packages="getTotalNumbersOfItems">
            </shipped-data-pie-chart>
          </div>

        </div>
        <div class="html2pdf__page-break"/>

        <div class="row" style="margin-top: 40px">
          <div class="col-md-8">
            <status-data-bar-chart :data="getStatusBarChartData"
                                   :number-of-shipped-packages="getTotalNumbersOfShippedItems">
            </status-data-bar-chart>
          </div>
          <div class="col-md-4">
            <status-data-pie-chart :data="getStatusPieChartData"
                                   :total-number-of-packages="getTotalNumbersOfItems"
                                   :number-of-shipped-packages="getTotalNumbersOfShippedItems">
            </status-data-pie-chart>
          </div>
        </div>
        <div class="html2pdf__page-break"/>

        <div class="row" style="margin-top: 40px;">
          <div class="col-md-12">
            <countries-data-geo-chart :data="countriesChartData"
                                      :number-of-shipped-packages="getTotalNumbersOfShippedItems">
            </countries-data-geo-chart>
          </div>
        </div>
        <div class="html2pdf__page-break"/>

        <div class="row" style="margin-top: 40px">
          <div class="col-md-12">
            <top-countries :data="topCountriesData" :state-table-columns="topCountriesColumns"></top-countries>
          </div>
        </div>
        <div class="html2pdf__page-break"/>


        <div class="row" style="margin-top: 40px">
          <div class="col-md-12">
            <usa-data-geo-chart :data="usaChartData"
                                :number-of-shipped-packages="getTotalNumbersOfShippedItems">
            </usa-data-geo-chart>
          </div>
        </div>
        <div class="html2pdf__page-break"/>

        <div class="row" style="margin-top: 40px">
          <div class="col-md-12">
            <top-usa-states :data="topUsaStatesData" :state-table-columns="topUsaStatesColumns"></top-usa-states>
          </div>
        </div>
        <div class="html2pdf__page-break"/>

        <div class="row" style="margin-top: 40px">
          <div class="col-md-12">
            <PackagesByStateTable :data="stateTableData"
                                  :state-table-columns="stateTableColumns"></PackagesByStateTable>
          </div>
        </div>

      </section>
    </vue-html2pdf>

  </div>
</template>

<script>
import ShippedDataPieChart from "@/pages/Dashboard/projects/charts/ShippedDataPieChart";
import ShippedDataBarChart from "@/pages/Dashboard/projects/charts/ShippedDataBarChart";
import StatusDataPieChart from "@/pages/Dashboard/projects/charts/StatusDataPieChart";
import StatusDataBarChart from "@/pages/Dashboard/projects/charts/StatusDataBarChart";
import PackagesByStateTable from "@/pages/Dashboard/projects/tables/PackagesByStateTable";
import CountriesDataGeoChart from "@/pages/Dashboard/projects/charts/CountriesDataGeoChart";
import UsaDataGeoChart from "@/pages/Dashboard/projects/charts/UsaDataGeoChart";
import TopCountries from "@/pages/Dashboard/projects/tables/TopCountries";
import TopUsaStates from "@/pages/Dashboard/projects/tables/TopUsaStates";
import {Progress} from "element-ui";
import VueHtml2pdf from 'vue-html2pdf'

export default {

  components: {
    ShippedDataPieChart,
    ShippedDataBarChart,
    StatusDataPieChart,
    StatusDataBarChart,
    [Progress.name]: Progress,
    PackagesByStateTable,
    CountriesDataGeoChart,
    UsaDataGeoChart,
    TopCountries,
    TopUsaStates,
    VueHtml2pdf
  },

  data() {
    return {
      loader: {},
      project: null,
      numberOfShippedPieChartData: [],
      numberOfShippedBarChartData: [],
      statusPieChart: [],
      statusBarChart: [],
      stateTableData: [],
      stateTableColumns: [],
      deliveryPercentage: 0,

      countriesChartData: [],
      usaChartData: [],

      topCountriesColumns: [],
      topCountriesData: [],

      topUsaStatesColumns: [],
      topUsaStatesData: [],

    }
  },

  created() {
    this.getData();
  },

  computed: {
    getNumberOfShippedPieChartData() {
      return {
        hoverBackgroundColor: "red",
        hoverBorderWidth: 10,
        labels: this.numberOfShippedPieChartData.labels,
        datasets: [
          {
            backgroundColor: this.numberOfShippedPieChartData.colors,
            data: this.numberOfShippedPieChartData.data
          }
        ]
      };
    },

    getNumberOfShippedBarChartData() {
      return {
        hoverBackgroundColor: "red",
        hoverBorderWidth: 10,
        labels: this.numberOfShippedBarChartData.labels,
        datasets: [
          {
            backgroundColor: this.numberOfShippedBarChartData.colors,
            data: this.numberOfShippedBarChartData.data
          }
        ]
      };
    },

    getTotalNumbersOfItems() {
      return this.numberOfShippedBarChartData.data.reduce((a, b) => a + b, 0)
    },

    getStatusPieChartData() {
      return {
        hoverBackgroundColor: "red",
        hoverBorderWidth: 10,
        labels: this.statusPieChart.labels,
        datasets: [
          {
            backgroundColor: this.statusPieChart.colors,
            data: this.statusPieChart.data
          }
        ]
      };
    },

    getStatusBarChartData() {
      return {
        hoverBackgroundColor: "red",
        hoverBorderWidth: 10,
        labels: this.statusBarChart.labels,
        datasets: [
          {
            backgroundColor: this.statusBarChart.colors,
            data: this.statusBarChart.data
          }
        ]
      };
    },

    getTotalNumbersOfShippedItems() {
      return this.statusBarChart.data.reduce((a, b) => a + b, 0)
    },

    getPrintedFileName() {
      return this.project.title + " - " + this.$moment(new Date()).format("MMMM Do YYYY");
    }
  },

  methods: {
    async getData() {
      try {
        this.loader = this.$loading.show({
          container: this.$refs.chartsContainer
        });

        let uuid = this.$route.params['uuid'];

        let res = await this.axios.get("projects/" + uuid + "/data");
        this.project = res.data.project;
        this.numberOfShippedPieChartData = res.data.numberOfShippedPieChartData;
        this.numberOfShippedBarChartData = res.data.numberOfShippedBarChartData;
        this.statusPieChart = res.data.statusPieChart;
        this.statusBarChart = res.data.statusBarChart;

        this.stateTableData = res.data.dataByState;
        this.stateTableColumns = res.data.stateTableColumns;

        this.countriesChartData = res.data.geoCountriesData;
        if (this.countriesChartData.length > 0)
          this.countriesChartData.unshift(['Country', 'Name', 'Packages Number', {role: 'tooltip', p: {html: true}}]);
        else this.countriesChartData.unshift(['country', 'Name'])


        this.usaChartData = res.data.geoUsaData;
        if (this.usaChartData.length > 0)
          this.usaChartData.unshift(['State', 'Name', 'Packages Number', {role: 'tooltip', p: {html: true}}]);
        else this.usaChartData.unshift(['State', 'Name']);

        this.topCountriesColumns = res.data.topCountriesColumns;
        this.topCountriesData = res.data.topCountriesData;

        this.topUsaStatesColumns = res.data.topUsaStatesColumns;
        this.topUsaStatesData = res.data.topUsaStatesData;

        this.deliveryPercentage = res.data.deliveryPercentage;

      } catch (e) {
        if (e.response !== undefined && e.response.status === 404) {
          this.$notify({
            message: "Project Not Found",
            timeout: 2000,
            type: 'danger'
          });
        }
      } finally {
        this.loader.hide();
      }
    },

    printWindow: function () {
      window.print();
    },

    generateReport() {
      this.$notify({
        message: "Please be patient it may take some time to complete this action.",
        timeout: 4000,
        type: 'info'
      });
      setTimeout(() => {
        this.$refs.html2Pdf.generatePdf();
      }, 1000);
    },

    viewProof() {
      if (this.project.proof_link)
        window.open(this.project.proof_link, '_blank');
    },

    viewDocuments() {
      this.$router.push("/projects/" + this.project.uuid + "/documents");
    },

    downloadList() {
      this.axios.get("projects/" + this.project.uuid + "/export-data", {responseType: 'blob'})
        .then(response => {
          let blob = new Blob([response.data], {type: response.headers['content-type']})
          let link = document.createElement('a');
          link.href = window.URL.createObjectURL(blob);
          link.download = "Project Data";
          link.click()
        })
    },

    getUrl(path) {
      return process.env.VUE_APP_SERVER_IMAGE_URL + path;
    },

    getSecureImgByUrl(path) {
      return process.env.VUE_APP_DOWNLOAD_IMAGE_URL + "?path=" + path +
        "&token=" + this.$store.getters["auth/token"];
    }
  }


}

</script>
<style scoped>

.general-info-card {
  padding: 15px 15px 25px;
  height: 90%;
}

.logo-container {
  text-align: right;
}

.left {
  text-align: left !important;
}

.logo-container .logo {
  width: 160px;
  height: 130px;
  border-radius: 5%;
}

.pdf-info {
  margin-top: 20px;
}

.pdf-info .card {
  padding: 20px 20px;
  border-radius: 10px;
}

.general-info-img-flex {
  display: flex;
  justify-content: space-between;
  margin-bottom: 50px;
}

.general-info-img {
  width: 100px;
}

.project-img {
  width: 300px;
}


</style>
